<template>
  <div class="categories-wrap"  >
    <div class="categories-box">
      <div class="categories-container flex-row">
        <div class="left_col">
          <div class="buttons-box filter-container">
            <el-button :icon="`iconfont icon-${btn.icon}`" :type="btn.class" size="mini" v-for="btn of categoryBtns" v-bind:key="btn.Id" class="filter-item" @click="onBtnClicked(btn.domId)">{{btn.name}}</el-button>
            <div @keyup.13="handleSearchCategoryTypes">
              <el-input placeholder="请输入搜索内容" v-model.trim="typesListQuery.key" size="mini" style="margin-top: 10px;width: 150px;margin-right:5px; height:30px;line-height:30px;" clearable>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click="handleSearchCategoryTypes">搜索</el-button>
            </div>
          </div>
          <el-card shadow="never" class="body-small categories-menu-card">
            <div slot="header" class="clearfix">
              <el-button type="text" style="padding: 0 11px" @click="getAllCategories">全部字典>></el-button>
            </div>
            <el-tree class="left_tree" :current-node-key="listQuery.TypeId" node-key="id" :highlight-current="true" @node-click="handleNodeClick" :data="categoryTypes" :expand-on-click-node="false" default-expand-all :props="categoryTypeProps">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span><i class="el-icon-menu" style="margin-right: 10px;"></i>{{ node.label }}</span>
              </span>
            </el-tree>
          </el-card>
        </div>
        <el-main class="categories-content flex-item">
          <sticky :className="'sub-navbar'">
            <div class="filter-container" style="white-space: nowrap; overflow-x: auto;">
              <el-input @keyup.enter.native="handleFilter" prefix-icon="el-icon-search"  size="mini" class="filter-item top_search_css" :placeholder="'名称/值/分类标识'" v-model.trim="listQuery.key" clearable> </el-input>
              <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
              <el-button :icon="`iconfont icon-${btn.icon}`" :type="btn.class" size="mini" v-for="btn of typesBtns" v-bind:key="btn.Id" class="filter-item" @click="onBtnClicked(btn.domId)">{{btn.name}}</el-button>
            </div>
          </sticky>
          <el-table height="calc(100% - 100px)" ref="mainTable"  :key='tableKey' :data="list"  border fit highlight-current-row style="width: 100%;" @row-click="rowClick"  @selection-change="handleSelectionChange">
            <!-- 通用表格循环组件 -  -->
            <general-table-column :headerList='headerList' :hasSelection='true' :isZdfl='true'></general-table-column>
          </el-table>
          <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
        </el-main>
      </div>
      <el-dialog :destroy-on-close="true"  v-loading="$store.state.listLoading" class="dialog-mini custom-dialog user-dialog" width="400px" title="添加分组"  :visible.sync="addTypesDialog">
        <el-form ref="categoryTypeForm" :model="categoryTypesInfo" :rules="categoryRules" el="categorys-tayps-form" label-width="80px">
          <el-form-item prop="id" label="分类标识">
            <el-input size="small" v-model="categoryTypesInfo.id" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item prop="name" label="分类名称">
            <el-input size="small" v-model="categoryTypesInfo.name" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align:right;margin-top: 10px;">
          <el-button size="small" type="cancel" @click="addTypesDialog = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleAddCategories">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog v-el-drag-dialog   class="dialog-mini" width="500px"  v-loading="$store.state.listLoading" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :rules="rules" ref="dataForm" :model="temp" label-position="right" label-width="100px">
          <span style="display:inline-block;font-size:8px;color:#909399;margin: 0px 30px 10px 30px;"><i style="color:#606266;"><span style="color:red;">*</span>Tips:特殊颜色标识：</i>蓝色:79BBFF;灰色:909399;橘色:FF8000;黄色:EAC100;绿色:67c23a;红色:FF2D2D;棕色:B87070;</span>
          <el-form-item size="small" :label="'Id'" prop="id">
            <el-input v-model="temp.id" :disabled="true" placeholder="系统自动处理"></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'值'" prop="dtCode">
            <el-input v-model="temp.dtCode" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'名称'" prop="name">
            <el-input v-model="temp.name" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'特殊颜色标识'" prop="dtValue">
            <el-input v-model="temp.dtValue" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'是否可用'" prop="enable">
              <el-select class="filter-item" v-model="temp.enable" placeholder="Please select">
              <el-option v-for="item in  statusOptions" :key="item.key" :label="item.display_name" :value="item.key">
            </el-option>
          </el-select>
          </el-form-item>
        <el-form-item size="small" :label="'排序号'">
            <el-input-number v-model="temp.sortNo" :min="0" :max="999" ></el-input-number>
        </el-form-item>
          <el-form-item size="small" :label="'描述'" prop="description">
            <el-input v-model="temp.description" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'所属分类ID'" prop="typeId">
            <el-select v-model="temp.typeId">
              <el-option v-for="(item, index) in categoryTypes" :key="index" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
      </el-form>
      <div slot="footer" >
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" v-if="dialogStatus=='create'" type="primary" @click="createData">确认</el-button>
        <el-button size="mini" v-else type="primary" @click="updateData">确认</el-button>
      </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import extend from "@/extensions/delRows.js"
import * as categorys from '@/api/categorys'
import waves from '@/directive/waves' // 水波纹指令
import Sticky from '@/components/Sticky'
import Pagination from '@/components/Pagination'
import elDragDialog from '@/directive/el-dragDialog'
import generalTableColumn from '@/components/generalTableColumn'
import * as basicConfiguration from '@/api/basicConfiguration/basicConfiguration' 
export default {
  name: 'category',
  components: { Sticky, Pagination ,generalTableColumn },
  mixins: [extend],
  directives: {
    waves,
    elDragDialog
  },
  data() {
    return {
      multipleSelection: [], // 列表checkbox选中的值
      tableKey: 0,
      list: [],
      total: 0,
      listQuery: { // 查询条件
        page: 1,
        limit: 50,
        key: undefined,
        appId: undefined,
        TypeId: undefined
      },
      statusOptions: [
        { key: true, display_name: '正常' },
        { key: false, display_name: '停用' }
      ],
      temp: {
        id: '', // 分类表ID（可作为分类的标识）
        dtCode: '',
        name: '', // 名称
        dtValue: '',
        enable: true, // 是否可用
        sortNo: '', // 排序号
        description: '', // 分类描述
        typeId: '', // 分类类型ID
        extendInfo: '' // 其他信息,防止最后加逗号，可以删除
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        appId: [{ required: true, message: '必须选择一个应用', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      },
      downloadLoading: false,
      headerList: [],
      searchCategories: '', // 分类搜索
      addTypesDialog: false,
      categoryTypes: [],
      searchCategoryType: '',
      categoryTypesInfo: {
        id: '',
        name: ''
      },
      categoryRules: {
        name: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        id: [{ required: true, message: '分类标识不能为空', trigger: 'blur' }]
      },
      categoryTypeProps: {
        children: 'children',
        label: 'name'
      },
      typesListQuery: {
        page: 1,
        limit: 99999,
        key: ''
      }
    }
  },
  filters: {
    statusFilter(disable) {
      const statusMap = {
        false: 'color-success',
        true: 'color-danger'
      }
      return statusMap[disable]
    }
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(['modulesTree']),
    categoryBtns() {
      var route = this.$route
      var elements = route.meta.elements
      elements = elements && elements.length > 0 && elements.filter(item => (item.domId === 'btnDelCategory' || item.domId === 'btnAddCategory')).sort((a, b) => {
        return a.sort - b.sort
      })
      return elements || []
    },
    typesBtns() {
      var route = this.$route
      var elements = route.meta.elements
      elements = elements && elements.length > 0 && elements.filter(item => (item.domId !== 'btnDelCategory' && item.domId !== 'btnAddCategory' && item.domId !== 'btnRefresh')).sort((a, b) => {
        return a.sort - b.sort
      })
      return elements || []
    },
    isShowOperation() {
      const route = this.$route
      const elements = route.meta.elements || []
      let flag = false
      elements.forEach(item => {
        if (item.domId === 'btnEdit') {
          flag = true
        }
      })
      return flag
    }
  },
  created() {
    
  },
  activated() {
      this.getList()
  },
  methods: {
    getAllCategories() {
      this.listQuery.TypeId = ''
      this.listQuery.page = 1
      this.getList()
    },
    rowClick(row) {
      this.$refs.mainTable.clearSelection()
      this.$refs.mainTable.toggleRowSelection(row)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onBtnClicked: function(domId) {
      console.log('you click:' + domId)
      switch (domId) {
        case 'btnAdd':
          this.handleCreate()
          break
        case 'btnEdit':
          if (this.multipleSelection.length !== 1) {
            this.$message({
              message: '只能选中一个进行编辑',
              type: 'error'
            })
            return
          }
          this.handleUpdate(this.multipleSelection[0])
          break
        case 'btnDel':
          if (this.multipleSelection.length < 1) {
            this.$message({
              message: '至少删除一个',
              type: 'error'
            })
            return
          }
          this.handleDelete(this.multipleSelection)
          break
        case 'btnAddCategory':
          this.addTypesDialog = true
          break
        case 'btnDelCategory':
          this.handleDeleteCategories()
          break
        default:
          break
      }
    },
    getList() {
      categorys.getList(this.listQuery).then(response => {
        if(response.code == '200'){
           this.loadCategoryTypes() 	
          response.columnHeaders.forEach((item) => {
            item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
          })
          this.headerList = response.columnHeaders.filter(u => u.browsable)
          this.headerList.forEach(i=>{
            if(i.key == 'dtCode'){
              i.description = '值'
            }
            if(i.key == 'dtValue'){
              i.description = '特殊颜色标识'
            }
          })
          this.list = response.data
          this.total = response.count
        }else{
          this.headerList = []
          this.list =[]
          this.$message({
            message:response.message,
            type:'warning'
          })
        }
        this.$nextTick(() => {
          this.$refs.mainTable.doLayout();
        });
      }).catch(()=>{
        this.headerList = []
        this.list =[]
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    resetTemp() {
      this.temp = {
        id: '',
        name: '',
        enable: true,
        dtCode: '',
        dtValue: '',
        sortNo: '',
        description: '',
        typeId: '',
        extendInfo: ''
      }
    },
    handleCreate() { // 弹出添加框
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() { // 保存提交
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$store.commit("updataListLoading",true)
          categorys.add(this.temp).then(() => {
            this.list.unshift(this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
            this.$store.commit("updataListLoading",false)
            this.getCategorys()
          }).catch(()=>{
            this.$store.commit("updataListLoading",false)
          })
        }
      })
    },
    handleUpdate(row) { // 弹出编辑框
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() { // 更新提交
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$store.commit("updataListLoading",true)
          const tempData = Object.assign({}, this.temp)
          categorys.update(tempData).then(() => {
            for (const v of this.list) {
              if (v.id === this.temp.id) {
                const index = this.list.indexOf(v)
                this.list.splice(index, 1, this.temp)
                break
              }
            }
            this.dialogFormVisible = false
            this.$notify({
              title: '成功',
              message: '更新成功',
              type: 'success',
              duration: 2000
            })
            this.$store.commit("updataListLoading",false)
            this.getCategorys()
          }).catch(()=>{
            this.$store.commit("updataListLoading",false)
          })
        }
      })
    },
    handleDelete(rows) { // 多行删除
      this.delrows(categorys, rows,()=>{this.getCategorys()})
    },
    // 添加分类
    handleAddCategories() {
      this.$refs['categoryTypeForm'].validate((valid) => {
        if (valid) {
          categorys.addType(this.categoryTypesInfo).then(() => {
            this.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success',
              duration: 2000
            })
            this.addTypesDialog = false
            this.categoryTypes.push(this.categoryTypesInfo)
            this.getCategorys()
            this.getCategoryType()
          })
        }
      })
    },
    // 删除分类
    handleDeleteCategories() {
      if (!this.listQuery.TypeId) {
        this.$message({
          message: '请选择需要删除的分类',
          type: 'error'
        })
        return
      }
      let sele=this.categoryTypes.filter((item)=>{return item.id==this.listQuery.TypeId})
      this.$confirm(`你确定要删除${sele[0].name?sele[0].name:"该"}字典分类吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'message-style',
        type: 'warning'
      }).then(() => {
        const ids = [this.listQuery.TypeId]
        this.$store.commit("updataListLoading",true)
        categorys.delType(ids).then(() => {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          })
          this.$store.commit("updataListLoading",false)
          this.categoryTypes = this.categoryTypes.filter(item => (item.id !== this.listQuery.TypeId))
          this.getCategorys()
          this.getCategoryType()
        }).catch(()=>{
          this.$store.commit("updataListLoading",false)
        })
      }).catch(() => {      
      }); 
    },
    // 获取字典类型
    getCategoryType(){
      this.$store.commit("updataListLoading",true)
       categorys.loadType({
        page: 1,
        limit: 99999,
        key: ''
      }).then(res => {
          this.$store.state.categoryType=[...res.data]
           this.$store.commit("updataListLoading",false)
      }).catch(()=>{
        this.$store.state.categoryType=[]
         this.$store.commit("updataListLoading",false)
      })
    },
    getCategorys(){//重新加载字典
      this.$store.commit("updataListLoading",true)
      basicConfiguration.getCategorys().then((res) => {
        if(res.code=='200'){
         this.$store.state.getCategorysData=res.result
          res.result.forEach((item)=>{
              if(item.id=="SYS_EXTERNALTYPE"){
                this.$store.state.xgdjType = item.categoryList  //相关单据类型
              }else if(item.id=="jumpType"){
                this.$store.state.jumpUrlList = item.categoryList  //获取跳转地址
              }else if(item.id=="SYS_InflgType"){
                this.$store.state.InFlgList = item.categoryList  //获取进出标志类型 
              }else if(item.id=="SYS_ORDERSTATUS"){
                this.$store.state.rderstayus = item.categoryList  //搜有的搜索
                let ss={dtCode:"",name:'全部单据状态'}
                this.$store.state.rderstayus.unshift(ss) 
              }else if(item.id=="SYS_OperateType"){
                this.$store.state.OperateTypeList = item.categoryList  //获取库存变动类型
              }
              else if(item.id=="SYS_BARCODETYPE"){
                this.$store.state.allBarCodeType = item.categoryList 
                let ss={dtCode:"",name:'全部条码类型'}
                this.$store.state.allBarCodeType.unshift(ss) 
              }
              else if(item.id=="SYS_GOODSBATCHTYPE"){
                this.$store.state.goodsBatchTypeData= item.categoryList  //获取项目编码类型
                let ss={sortNo:-1,name:'全部项目编码类型'}
                this.$store.state.goodsBatchTypeData.unshift(ss) 
              }
              else if(item.id=="COMMON_STATUS"){
                this.$store.state.commonStatus = item.categoryList  //状态 正常 异常
              }
              else if(item.id=="SYS_INBOUNDTYPE"){
                this.$store.state.inboundtype = item.categoryList  //入库类型
              }
              else if(item.id=="SYS_STATUS"){
                this.$store.state.status = item.categoryList  //系统状态
              }
              else if(item.id=="SYS_Enable"){
                this.$store.state.enable = item.categoryList  //有效标志
                this.$store.state.activetext=item.categoryList.length>0?item.categoryList.filter((item)=>{return item.dtCode=='1'})[0].name:'有效'
                this.$store.state.inactivetext=item.categoryList.length>0?item.categoryList.filter((item)=>{return item.dtCode=='0'})[0].name:'无效'
              }
              else if(item.id=="SYS_LockYn"){
                this.$store.state.lockyn = item.categoryList  //库存锁
              }
              else if(item.id=="SYS_UserYn"){
                this.$store.state.useryn = item.categoryList  //是否可用
              }
              else if(item.id=="SYS_OpenJobStatus"){
                this.$store.state.openjobstatus = item.categoryList  //定时任务状态
              }
              else if(item.id=="SYS_PrintStatus"){
                this.$store.state.printstatus = item.categoryList  //打印状态
              }
              else if(item.id=="SYS_NeedQCYn"){
                this.$store.state.needqcyn = item.categoryList  //需要质检状态
              }
              else if(item.id=="SYS_Picking"){
                this.$store.state.picking = item.categoryList  //是否已生成拣货
              }
              else if(item.id=="SYS_CHANGEREASON"){
                this.$store.state.changereason = item.categoryList  //货物移动原因
              }
              else if(item.id=="SYS_OTHERSEND_RESPONSE"){
                this.$store.state.othersendResponse = item.categoryList  //其他收发货原因
              }
              else if(item.id=="SYS_GOODSCHANGE_REASON"){
                this.$store.state.goodschangeReason = item.categoryList  //借调类型
              }
              else if(item.id=="SYS_DETAILSTATUS"){
                this.$store.state.deteilstatus = item.categoryList  //申请明细单状态
              }
              else if(item.id=="basicState"){
                this.$store.state.basicState = item.categoryList  ////基础配置 角色 用户 部门
                
              }
              else if(item.id=="SYS_YesNoStatus"){
                this.$store.state.yesnoatatus = item.categoryList  ////返回 是 否
              }
              else if(item.id=="SYS_BeihuoYn"){
                this.$store.state.beihuoyn = item.categoryList  ////返是否需要备货
              }
              else if(item.id=="allowOrNot"){
                this.$store.state.allowOrNot = item.categoryList  //是否允许出入库  允许 不允许
              }
              else if(item.id=="SYS_ThirdType"){
                this.$store.state.thirdType = item.categoryList  ////返回第三方 信息
              }
              else if(item.id=="SYS_IQCStatus"){
                this.$store.state.iqcStatus = item.categoryList  ////质检的状态
                 let ss={dtCode:"",dtValue:"",name:'全部质检状态'}
                this.$store.state.iqcStatus.unshift(ss)
              }
              else if(item.id=="SYS_APPLYTYPE"){
                this.$store.state.applytype = item.categoryList  ////申请类型
              }
              // else if(item.id=="SYS_GOODSPRINTMODEL_SEARCH"){
              //   this.$store.state.bhPrintModel = item.categoryList  ////条码类别 暂时只用于备货
              // }
              else if(item.id=="SYS_LogType"){//日志类型
                this.$store.state.logType = item.categoryList
              }
              else if(item.id=="SYS_PRINTTYPE"){//打印类型
                this.$store.state.caprinttype = item.categoryList
              }
              else if(item.id=="upLoadType"){//上传类型
                this.$store.state.upLoadTypeData = item.categoryList
              }
              else if(item.id=="ROLE_TYPE"){  //角色筛选 用于判定该角色能干什么事情
                this.$store.state.roleType = item.categoryList
              }
              else if(item.id=="SYS_TaskPriority"){  //任务优先级
                this.$store.state.priorityData = item.categoryList
              }
              else if(item.id=="SYS_PRINTERSTATUS"){  //打印机状态
                this.$store.state.PrinterStatusList = item.categoryList
              }
          })
        }
         this.$store.commit("updataListLoading",false)
      }).catch(() => {
        this.$notify({
          title: '失败',
          message: '获取字典失败',
          type: 'error',
          duration: 2000
        })
        this.$store.commit("updataListLoading",false)
        this.$store.state.PrinterStatusList=[]
        this.$store.state.upLoadTypeData=[]
        this.$store.state.priorityData=[]
        this.$store.state.roleType=[]
        this.$store.state.xgdjType=[]
        this.$store.state.jumpUrlList=[]
        this.$store.state.InFlgList =[]
        this.$store.state.caprinttype=[]
        this.$store.state.OperateTypeList=[]
        this.$store.state.rderstayus=[]
        this.$store.state.goodsBatchTypeData=[]
        this.$store.state.commonStatus=[]
        this.$store.state.inboundtype=[]
        this.$store.state.status=[] 
        this.$store.state.enable=[]
        this.$store.state.lockyn=[]
        this.$store.state.useryn=[]
        this.$store.state.openjobstatus=[]
        this.$store.state.printstatus=[]
        this.$store.state.needqcyn=[]
        this.$store.state.picking=[]
        this.$store.state.changereason=[]
        this.$store.state.othersendResponse=[]
        this.$store.state.goodschangeReason=[]
        this.$store.state.deteilstatus=[]
        this.$store.state.basicState =[]
        this.$store.state.yesnoatatus=[]
        this.$store.state.beihuoyn=[]
        this.$store.state.allowOrNot=[]
        this.$store.state.thirdType=[]
        this.$store.state.rderstayus=[]
        this.$store.state.iqcStatus=[]
        this.$store.state.applytype=[]
        this.$notify({
          title: '失败',
          message: '获取字典失败',
          type: 'error',
          duration: 2000
        })       
      })
    },
    // 搜索分类
    handleSearchCategoryTypes() {
      this.typesListQuery.page = 1
      this.loadCategoryTypes()
    },
    // 获取分类列表
    loadCategoryTypes() {
      categorys.loadType(this.typesListQuery).then(res => {
        this.categoryTypes = [...res.data]
      })
    },
    handleNodeClick(val) {
      this.listQuery.TypeId = val.id
      this.getList()
    }
  },
  watch:{
    //处理通过tags关闭页面多选不清空的问题
    '$route'(){
      if(this.$store.state.isLeaveTags == true){
        this.multipleSelection = [];
        this.$refs.mainTable.clearSelection()
        this.$store.state.isLeaveTags = !this.$store.state.isLeaveTags
      }
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
	.dialog-mini .el-select{
		width:100%;
	}
  .categories-wrap{
    width: 100%;
    height: calc(100vh - 84px);
    box-sizing: border-box;
    padding: 10px;
    .categories-menu-card{
      border: none;
      height: calc(100% - 86px);
      overflow: auto;
      .el-card__body{
        height: auto;
      }
    }
  }
  .categories-box{
    height: 100%;
    background: #efefef;
    box-sizing: border-box;
    .categories-container{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      .categories-item{
        height: 30px;
        line-height: 30px;
        padding: 0 10px !important;
      }
    }
    .left_col{
      padding: 0 4px;
    }
    .buttons-box{
      padding: 10px 10px;
      background: #fff;
    }
    .categories-content{
      height: 100%;
      padding: 0 4px;
      .buttons-box{
        text-align: right;
      }
    }
  }
  .left_tree{
    font-size: 14px;
  }
</style>
